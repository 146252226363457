import {
  LiveKitRoom,
  RoomAudioRenderer,
} from '@livekit/components-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getToken, getInterviewDetails, startInterview, validateAuthToken } from '../services/api';
import ParticipantAvatar from './ParticipantAvatar';
import DescriptionPopup from './DescriptionPopup';
import CustomDisconnectButton from './CustomDisconnectButton';
import { HashLoader } from 'react-spinners';

const NewInterview = () => {
  const { id } = useParams();
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [interviewDetails, setInterviewDetails] = useState(null);
  const [showDescriptionPopup, setShowDescriptionPopup] = useState(false);

  const getInitials = (name) => {
    return name
      ?.split(' ')
      ?.map((part) => part.charAt(0).toUpperCase())
      ?.join('');
  };

  useEffect(() => {
    const initializeInterview = async () => {
      try {
        // Validate the authentication token
        validateAuthToken();

        // Start the interview
        await startInterview(id);

        // Fetch LiveKit token and interview details
        localStorage.setItem('interviewId', id);
        const fetchedToken = await getToken(id);
        const fetchedInterview = await getInterviewDetails(id);

        setToken(fetchedToken);
        setInterviewDetails(fetchedInterview);
      } catch (err) {
        console.error(err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    initializeInterview();
  }, [id]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-blue-900">
        <div className="text-center">
          <HashLoader color="#ffffff" />
          <p className="text-gray-500 mt-4">Starting interview...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen bg-blue-900">
        <div className="bg-white shadow-md rounded-lg p-8 text-center">
          <p className="text-red-500 text-lg font-semibold">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-blue-900 relative">
      <LiveKitRoom
        video={false}
        audio={true}
        token={token}
        serverUrl={process.env.REACT_APP_LIVEKIT_URL}
        data-lk-theme="default"
        className="w-screen h-screen flex"
      >
        <div className="flex-1 flex flex-col items-center justify-center bg-blue-800 relative">
          <ParticipantAvatar type="agent" label="L" color="blue" />
          {interviewDetails && (
            <div className="text-white text-center mt-4">
              <p className="font-semibold text-lg">{interviewDetails?.job?.interviewerName}</p>
              <p className="text-sm font-light">AI Interview Expert</p>
            </div>
          )}
        </div>

        <div className="flex-1 flex flex-col items-center justify-center bg-blue-800 relative">
          <ParticipantAvatar
            type="user"
            label={getInitials(interviewDetails?.candidate?.name)}
            color="green"
          />
          {interviewDetails && (
            <div className="text-white text-center mt-4">
              <p className="font-semibold text-lg">
                {interviewDetails.candidate.name}
              </p>
              <p className="text-sm font-light">
                {interviewDetails.candidate.designation}
              </p>
            </div>
          )}
        </div>
        <RoomAudioRenderer />
        <div className="absolute bottom-16 left-1/2 transform -translate-x-1/2">
          <CustomDisconnectButton />
        </div>
      </LiveKitRoom>

      {interviewDetails && (
        <div className="absolute top-8 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-50 text-white p-8 rounded-lg shadow-lg max-w-3xl text-center">
          <h1 className="text-2xl font-bold mb-1">{interviewDetails.job.title}</h1>
          <p className="text-lg font-light mb-1">
            {interviewDetails.job.position} at{' '}
            {interviewDetails.job.organization},{' '}
            {interviewDetails.job.location}
          </p>
          <p className="text-md mb-1">
            Estimated Duration: {interviewDetails.estimatedDuration} minutes
          </p>
          <a
            href="#"
            className="text-blue-300 underline"
            onClick={(e) => {
              e.preventDefault();
              setShowDescriptionPopup(true);
            }}
          >
            View Job Description
          </a>
        </div>
      )}

      {showDescriptionPopup && (
        <DescriptionPopup
          title="Job Description"
          content={interviewDetails.job.description}
          onClose={() => setShowDescriptionPopup(false)}
        />
      )}
    </div>
  );
};

export default NewInterview;
