import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const WelcomeScreen = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const handleStartInterview = () => {
    navigate(`/job-interview/${id}`);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-blue-900 text-white p-6 text-center">
      <div className="bg-blue-800 p-8 rounded-lg shadow-lg w-1/2">
        <h1 className="text-3xl font-bold mb-4">Welcome</h1>
        <p className="text-lg font-light mb-4">Please read the following guidelines before starting:</p>
        <ul className="text-left text-md mb-6 list-disc list-inside">
          <li>Ensure you are in a quiet environment.</li>
          <li>Use headphones for better audio quality.</li>
          <li>Check your microphone and internet connection.</li>
          <li>Answer all questions honestly and clearly.</li>
          <li>After you click "Start Interview", you need to allow the browser to use the Microphone.</li>
          <li>You can end the interview anytime by clicking the "End Interview" button.</li>
        </ul>
        <button
          onClick={handleStartInterview}
          className="bg-green-700 hover:bg-green-600 text-white font-semibold px-6 py-3 rounded-lg shadow-md transition"
        >
          Start Interview
        </button>
      </div>
    </div>
  );
};

export default WelcomeScreen;
