import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import JobDescription from './components/JobDescription';
import ThankYou from './components/ThankYou';
import NewInterview from './components/NewInterview';
import WelcomeScreen from './components/WelcomeScreen';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:interviewId" element={<Home />} />
        <Route path="/interview/:id" element={<WelcomeScreen />} />
        <Route path="/job-interview/:id" element={<NewInterview />} />
        <Route path="/job-description/:id" element={<JobDescription />} />
        <Route path="/thanks" element={<ThankYou />} />
      </Routes>
    </Router>
  );
}

export default App; 
